html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  width: 1920px;
  height: 1080px;
  overflow: hidden !important;
}

.App {
  width: 1920px;
  height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lottie-container {
  width: 100%;
  height: 100%;
}

.lottie-loading-container {
  width: 300px;
  height: 300px;
}

.newsTicker > text {
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%);

  -moz-animation: my-animation 40s linear infinite;
  -webkit-animation: my-animation 40s linear infinite;
  animation: my-animation 40s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(0%);
  }
  to {
    -moz-transform: translateX(-280%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-280%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -moz-transform: translateX(-280%);
    -webkit-transform: translateX(-280%);
    transform: translateX(-280%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.chartSingleContainer {
  width: 1540px;
  height: 580px;
  position: absolute;
  top: 270px;
}

.chartSingleChild {
  height: 520px !important;
  width: 1480px !important;
  position: absolute;
  bottom: 0px;
  left: 30px;
  image-rendering: optimizeSpeed; /* Older versions of FF */
  image-rendering: -moz-crisp-edges; /* FF 6.0+ */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non standard naming) */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: crisp-edges; /* Possible future browsers. */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non standard naming) */
}

.chartMultiContainer {
  width: 1540px;
  height: 580px;
  position: absolute;
  top: 270px;
}

.chartMultiChild {
  height: 750px !important;
  width: 1260px !important;
  position: absolute;
  bottom: 0px;
  right: -130px;
  image-rendering: optimizeSpeed; /* Older versions of FF */
  image-rendering: -moz-crisp-edges; /* FF 6.0+ */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non standard naming) */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: crisp-edges; /* Possible future browsers. */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non standard naming) */
}
